import React, { useCallback, useEffect, useState } from "react";
import externalContracts from "./contracts/external_contracts";
import { useStaticJsonRPC } from "./hooks";
import { useContractLoader, useUserProviderAndSigner } from "eth-hooks";
import { Header, Footer } from "./components";
import { ethers } from "ethers";
import { INFURA_ID } from "./constants";
import { Transactor, Web3ModalSetup } from "./helpers";
import { notification } from "antd";
import { utils } from "ethers/lib.esm";

import getUrlParam from "./helpers/getUrlParam";
import { create } from "ipfs-http-client";

const { MultiCall } = require("@indexed-finance/multicall");
let web3Modal = Web3ModalSetup();

function App() {
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [accountBalance, setBalance] = useState(0);
  const [priceForAddress, setPriceForAddress] = useState(0);
  const impersonate = false;
  /*const targetNetworkLocal = {
    name: "polygon",
    chainId: 31337,
    rpcUrlInfura: "https://polygon-mainnet.infura.io/v3/" + INFURA_ID,
    rpcUrl: "http://localhost:8545/",
    blockExplorer: "https://polygonscan.com/",
  };*/
  const targetNetwork = {
    name: "polygon",
    chainId: 137,
    rpcUrlInfura: "https://polygon-mainnet.infura.io/v3/" + INFURA_ID,
    rpcUrl: "https://polygon-rpc.com/",
    blockExplorer: "https://polygonscan.com/",
  };

  const rpcUrl =
    targetNetwork[getUrlParam("rpc")] ||
    process.env["REACT_APP_RPC_URL_" + targetNetwork.chainId] ||
    targetNetwork.rpcUrl;

  web3Modal = Web3ModalSetup(rpcUrl);

  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : rpcUrl || targetNetwork.rpcUrl,
  ]);

  const provider = localProvider || injectedProvider;

  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, false);
  const userSigner = userProviderAndSigner.signer;

  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  if (selectedChainId && localChainId && selectedChainId !== localChainId) {
    notification.warn({
      message: "Wrong Network!",
      description: "Please change the network to " + targetNetwork.name,
      placement: "topRight",
      key: "wrong-network",
      duration: null,
    });
  } else {
    notification.close("wrong-network");
  }

  const contractConfig = { deployedContracts: {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  const loadData = useCallback(
    async (functions = []) => {
      if (!functions.length) {
        functions = ["DCT::getPriceForAddress", "CurrentBalance"];
      }

      if (provider && functions.length) {
        const multi = new MultiCall(provider);

        const inputs = [];
        const functionsCalled = [];
        if (readContracts.DCT) {
          if (functions.includes("DCT::getPriceForAddress") && address) {
            functionsCalled.push("DCT::getPriceForAddress");
            inputs.push({
              interface: externalContracts[targetNetwork.chainId].contracts.DCT.abi,
              target: externalContracts[targetNetwork.chainId].contracts.DCT.address,
              function: "getPriceForAddress",
              args: [address],
            });
          }
        }

        if (inputs.length) {
          const roundData = await multi.multiCall(inputs);

          for (let i = 0; i < inputs.length; i++) {
            switch (inputs[i].function) {
              case "getPriceForAddress":
                setPriceForAddress(roundData[1][i]);
                break;
              default:
                break;
            }
          }
        }

        if (address) {
          const balance = await provider.getBalance(address);
          setBalance(balance);
        }
      }
    },
    [provider, address, impersonate, readContracts.DCT, targetNetwork.chainId],
  );

  useEffect(() => {
    (async function () {
      await loadData();
    })();
  }, [address, impersonate, readContracts.DCT, loadData]);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const tx = Transactor(userSigner);

  const publicMint = useCallback(
    async (amount, metadataUrl) => {
      if (writeContracts.DCT && address) {
        tx(writeContracts.DCT.publicMint(mintFor || address, metadataUrl, { value: amount }), result => {
          if (result.hash) {
            notification.close(result.hash);
            if (result.status === "confirmed" || result.status === 1) {
              notification.close(result.hash);
              notification.success({
                message: "Transaction success!",
                description: "NFT Minted successfully.",
                placement: "topRight",
              });

              loadData();
            }
          }
        });
      }
    },
    [address, tx, writeContracts.DCT, loadData],
  );

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();

    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", a => {
      if (a && a[0]) {
        //    window.location.reload();
        setInjectedProvider(new ethers.providers.Web3Provider(provider));
      } else {
        logoutOfWeb3Modal();
      }
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [file, setFile] = useState(null);
  const [minting, setMinting] = useState(false);
  const [name, setName] = useState("");
  const [mintFor, setMintFor] = useState("");
  const [description, setDescription] = useState("");
  const [urlArr, setUrlArr] = useState([]);

  const projectId = "2FZr3aQ28C85nfbmlhvn07X7k4x";

  const projectSecret = "c16a54b7a8735b671136d2e535c5d6a4";
  const auth = "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

  const client = create({
    host: "infura-ipfs.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: auth,
    },
  });

  const retrieveFile = e => {
    const data = e.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(data);

    reader.onloadend = () => {
      setFile(Buffer(reader.result));
    };

    e.preventDefault();
  };

  const metadata = {
    name: "",
    image: "",
    description: "",
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!file || !name || !description) {
      alert("Please fill all fields.");
      return;
    }

    if (!utils.isAddress(mintFor || address)) {
      alert("The address is not correct.");
      return;
    }

    try {
      setMinting(true);
      const createdImage = await client.add(file);
      const url = `https://infura-ipfs.io/ipfs/${createdImage.path}`;
      const imageIpfsUrl = `ipfs://${createdImage.path}`;
      metadata.image = imageIpfsUrl;
      metadata.name = name;
      metadata.description = description;

      const createdJson = await client.add(JSON.stringify(metadata));
      const metadataUrlIpfs = `ipfs://${createdJson.path}`;

      setUrlArr(prev => [...prev, url]);

      await publicMint(ethers.BigNumber.from(priceForAddress).toString(), metadataUrlIpfs);
      setMinting(false);
    } catch (error) {
      console.log(error.message);
      setMinting(false);
    }
  };

  let message = ``;
  let valid = true;
  const requiredBalance = utils.formatEther(priceForAddress + "") + " MATIC";

  if (!address) {
    valid = false;
    message = (
      <span className={`flex flex-col items-center justify-center space-y-2`}>
        <span className={`flex-1`}>Please connect a wallet to create a digital collectible.</span>{" "}
        <button
          onClick={loadWeb3Modal}
          className="inline-flex flex-1 items-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-purple-500 shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
        >
          Connect Now
        </button>
      </span>
    );
  } else if (ethers.BigNumber.from(accountBalance).lte(ethers.BigNumber.from(priceForAddress))) {
    valid = false;
    message =
      "Your current MATIC balance is " +
      utils.formatEther(accountBalance + "") +
      " MATIC and that is less than what is required to mint a collectible. You need " +
      requiredBalance +
      " PLUS the gas fees.";
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Header
          address={impersonate || address}
          targetNetwork={targetNetwork}
          login={loadWeb3Modal}
          logout={logoutOfWeb3Modal}
          accountBalance={accountBalance}
        />
        <div className="flex-column flex min-h-screen items-center  justify-center">
          {valid ? (
            <div className="flex-column flex items-center justify-center gap-y-6">
              <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 py-8 text-white">
                <div className="space-y-8 divide-y divide-white">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-white">Please note</h3>
                      <ul className={`max-w-md list-disc text-sm text-white`}>
                        <li className="mt-1">
                          This information will be displayed publicly so be careful what you share. <br />
                        </li>
                        <li className="mt-1">
                          Once you mint your collectible, it may take upto 10 minutes for Instagram to show that in your
                          wallet.
                        </li>
                      </ul>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <label htmlFor="price" className="block text-sm font-medium text-white">
                          Cost per mint
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="price"
                            id="price"
                            value={utils.formatEther(priceForAddress + "") + " MATIC"}
                            readOnly
                            className="block w-full min-w-0 flex-1 flex-1 rounded-md border-white text-gray-800 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="name" className="block text-sm font-medium text-white">
                          Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            onChange={e => setName(e.target.value)}
                            value={name || ""}
                            placeholder={`Eg. My first digital collectible`}
                            className="block w-full min-w-0 flex-1 flex-1 rounded-md border-white text-gray-800 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="description" className="block text-sm font-medium text-white">
                          Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            onChange={e => setDescription(e.target.value)}
                            value={description || ""}
                            placeholder={`Eg. This digital collectible is generated using https://dct.bakemytoken.com`}
                            className="block w-full rounded-md border-white text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <p className="mt-2 text-sm text-white">Write a few sentences about this collectible.</p>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="cover-photo" className="block text-sm font-medium text-white">
                          Photo
                        </label>
                        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-white px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-white"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-white">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                              >
                                <span className={`px-2`}>Upload a photo</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  onChange={retrieveFile}
                                  className="sr-only"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-white">PNG, JPG, GIF up to 10MB</p>
                          </div>
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        {urlArr.length !== 0 ? urlArr.map((el, i) => <img key={i} src={el} alt="nfts" />) : null}
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="address" className="block text-sm font-medium text-white">
                          Mint and send to address
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="address"
                            id="address"
                            autoComplete="address"
                            onChange={e => setMintFor(e.target.value)}
                            value={mintFor || address}
                            className="block w-full min-w-0 flex-1 rounded-md border-white text-gray-800 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={minting}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-white py-2 px-4 text-sm font-medium text-purple-600 shadow-sm hover:opacity-75"
                    >
                      {minting ? `Minting...` : `Upload and Mint`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div>{message}</div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
