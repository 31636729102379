const DigitalCollectible = require("./DigitalCollectible.json");
// Mainnet DAI, Optimism and Arbitrium Rollup Contracts with local addresses
module.exports = {
  31337: {
    contracts: {
      DCT: {
        abi: DigitalCollectible.abi,
        address: process.env.REACT_APP_DCT_PROXY_ADDRESS_LOCALHOST || "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
      },
    },
  },
  137: {
    contracts: {
      DCT: {
        abi: DigitalCollectible.abi,
        address: process.env.REACT_APP_DCT_PROXY_ADDRESS_137,
      },
    },
  },
};
