/* This example requires Tailwind CSS v2.0+ */

import { TELEGRAM_ID } from "../constants";

export default function Footer(props) {
  const navigation = [];

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <p className="basis-1/3 text-base text-gray-400 md:order-1">
          Made with ❤️ by{" "}
          <a href={`https://twitter.com/nivesh_saharan`} target={`_blank`}>
            Nivesh
          </a>{" "}
          for you!
        </p>
        <p className="basis-1/2 text-base text-gray-400 md:order-1">
          &copy; {new Date().getFullYear()} Bake My Token. All Rights Reserved.
        </p>
        <div className="flex justify-center space-x-2 md:order-3">
          {navigation.map(item => (
            <a
              key={item.name}
              title={item.name}
              href={item.href}
              target={`_blank`}
              className="text-gray-400 hover:text-purple-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}
